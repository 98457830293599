.nav-tabs {
  &.nav-tab-solid {
    border-bottom:none;

    .nav-item {
      margin-right: $nav-tab-solid-spacing;
      &:last-child {
        margin-right:0;
      }
    }

    .nav-link {
      border:none;
      background-color: $nav-tab-solid-bg;
      color : $nav-tab-solid-color;

      @include hover-focus {
        background-color: $nav-tab-solid-hover-bg;
        color : $nav-tab-solid-hover-color;
      }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      color: $nav-tab-solid-active-color;
      background-color: $nav-tab-solid-active-bg;
    }

  }
}