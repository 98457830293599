.form-section {
  padding: $form-section-padding;
  border: $form-section-border-width solid $form-section-border-color;
  background-color: $form-section-default-bg;

  &.form-section-sm {
    padding: $form-section-padding-sm;
  }

  &.form-section-lg {
    padding: $form-section-padding-lg;
  }

  @each $color, $value in $theme-colors {
    &.form-section-#{$color} {
      background-color: $value;
      color:color-yiq($value);
    }
  }
  
}
