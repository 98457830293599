/**
Emulate link behavior
 */
@each $key, $val in $theme-colors {
  .link-#{$key} {
    color: $val !important;
    
    &:not(a) {
      text-decoration: $link-decoration;
      display: inline;

      @include hover-focus {
        text-decoration: $link-hover-decoration;
        cursor: pointer;
      }
    }
    
  }
}