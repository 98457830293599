@include media-breakpoint-up(md) {
  .side-nav-minimize {
    .side-nav {
      
      width: $side-bar-minimize-width;

      .side-nav-header-logo {
        padding: $side-bar-minimize-header-logo-padding;
      }

      .sub-nav {
        > .nav {
          padding-left:0;
        }

        /*&.show > .nav {
          @if($side-bar-shadow-enable) {
            box-shadow: $side-bar-shadow;
          }
  
          position: absolute;
          width: auto;
          right: -144px;
          margin-top: -36px;
          background: white;
          height: 30vh;
        }*/
      }

      .hide-min, .hide-min-inline-block, .hide-min-inline {
        display:none;
      }

      .show-min {
        display:block;
      }

      .show-min-inline {
        display:inline;
      }

      .show-min-inline-block {
        display:inline-block;
      }

      .nav-item {
        &.open {
          
          //min-width:150px;
          height: $side-bar-minimize-item-height;
          
          > .nav-link {
            position:absolute;
            //padding-right: $side-bar-minimize-width;
            color: $side-bar-light-active-color;
            background-color: $side-bar-light-active-bg;
            //white-space: nowrap;
            //width: auto;
            width: $side-bar-minimize-sub-width + $side-bar-minimize-width;
            
            > .nav-label {
              margin-left: 2rem;
              color: $side-bar-light-active-color;
            }
          }

          

          .sub-nav {

            @if($side-bar-shadow-enable) {
              box-shadow: $side-bar-shadow;
            }
            
            position:absolute;
            margin-left: $side-bar-minimize-width;
            background-color: darken($light, 3);
            margin-top: $side-bar-minimize-item-height;
            //width: auto;
            //white-space: nowrap;
            width: $side-bar-minimize-sub-width;
            
            .nav-link, a.nav-label {
              width:100%;
            }
          }
        }
      }
      
      .nav-link, a.nav-label {
        overflow: hidden;
        height: $side-bar-minimize-item-height;
        width: $side-bar-minimize-width;

        .fal, .fas, .fab, .fa, .far, svg {
          margin-left: 0.25em;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .side-nav {
    [data-toggle="minimize"] {
      display:none;
    }
  }
}

.side-nav {

  @extend .d-flex;
  @extend .flex-column;

  @if($side-bar-shadow-enable) {
    box-shadow: $side-bar-shadow;
  }

  .hide-min {
    display:block;
  }

  .hide-min-inline {
    display:inline;
  }

  .hide-min-inline-block {
    display:inline-block;
  }
  
  .show-min, .show-min-inline, .show-min-inline-block {
    display:none;
  }

  .side-nav-header-logo {
    padding: $side-bar-header-logo-padding;
  }

  .nav-section {
    //flex-grow: 1;
  }

  .nav-link {

    > .fas, > .fab, > .fa, > .far, > svg {
      margin-right: map-get($spacers, 1);
    }

    a {
      > .fas, > .fab, > .fa, > .far, > svg {
        margin-right: map-get($spacers, 1);
      }
    }

    &.nav-icon {

    }
  }



  &.side-nav-fixed {
    position: fixed;
    height: 100%;
    z-index: $zindex-fixed;
    left:0;
    padding: 0;
  }

  /*&.ps-enabled {
    overflow: hidden;
  }*/

  .scrollable-region {

    overflow: auto;
    max-height: 100%;

    // this will fill the remaining height of the sidebar
    flex-grow: 1;

    &.ps-enabled {
      overflow: hidden;
    }
  }

  .nav-header {
    color : $side-bar-header-color;

    @if $side-bar-header-uppercase {
      text-transform: uppercase;
    }

    &li {
      font-weight: $font-weight-bold;
    }

    padding: $side-bar-header-padding-y $side-bar-header-padding-x;

  }

  .nav {
    
    .nav-item {

      font-weight: $side-bar-nav-font-weight;

      a.nav-label {

      }

      // this adds a caret which indicates a submenu
      // which will only draw when .nav-toggle is added with the .nav-item
      &.nav-toggle {

        &:not(.collapsible) {
          .sub-nav {
            //display: none;
          }
        }

        > span {
          cursor:pointer;
        }

        > a, > span {
          @include caret(down);
          &:after {
            float: right;
            margin-top: 0.56em;
            @include transition($side-bar-caret-transition);
          }
        }

        // rotate the caret
        &.open {
          > a, > span {
            &:after {
              transform: $side-bar-caret-open-t;
            }
          }
        }

      }

      // this case indicates that a nav item has a link and is expandable on the icon
      &.nav-link-toggle {
        [data-toggle="sub-nav"] {

          cursor:pointer;
          
          &.carat {
            float: right;
            @include transition($side-bar-caret-transition);
          }
          
        }

        &.open .carat[data-toggle="sub-nav"] {
          transform: $side-bar-caret-open-t;

        }
      }

      //.nav-label {
      //  fa,far,fas,fab,svg {
      //    margin-right: map-get($spacers, 1);
      //  }
      //}
    }
  }

  .sub-nav > .nav {
    @extend .d-flex;
    @extend .flex-column;
    padding-left: $spacer;
  }

  .divider {
    @include nav-divider();
  }

  .nav-pills .nav-link {
    border-radius: 0;
  }




}


// handle side nav responsive
// similar to navbar-expand-* except we collapse all nav sections
// when it reaches a specific breakpoint
.side-nav {
  &.side-nav-fold {
    @each $breakpoint in map-keys($grid-breakpoints) {
      $next: breakpoint-next($breakpoint, $grid-breakpoints);
      $infix: breakpoint-infix($next, $grid-breakpoints);

      &#{$infix} {
        @include media-breakpoint-down($breakpoint) {
          .nav-icon .nav-label {
            display: none;
          }
        }

        @include media-breakpoint-up($next) {

        }
      }
    }
  }
}

.side-nav {
  &.side-nav-collapse {
    @each $breakpoint in map-keys($grid-breakpoints) {
      $next: breakpoint-next($breakpoint, $grid-breakpoints);
      $infix: breakpoint-infix($next, $grid-breakpoints);



      &#{$infix} {
        @include media-breakpoint-down($breakpoint) {
          &.side-nav-fixed {
            position: relative !important;
          }

          @include make-col(12, $grid-columns);



        }

        @include media-breakpoint-up($next) {

          > .nav-section.collapse {
            display: block;
          }

          .side-nav-toggle {
            display: none;
          }

          .collapse {
            //display: block;
          }


        }
      }


    }
  }

  //.nav-fold {
  //  @each $breakpoint in map-keys($grid-breakpoints) {
  //    $next: breakpoint-next($breakpoint, $grid-breakpoints);
  //    $infix: breakpoint-infix($next, $grid-breakpoints);
  //    &#{$infix} {
  //      @include media-breakpoint-down($breakpoint) {
  //        display: none;
  //      }
  //      
  //    }
  //  }
  //}


}


// default theme
.side-nav {
  background-color: $light;

  .sub-nav {
    background-color: darken($light, 3);
  }

  .nav-link, a.nav-label {
    color: $side-bar-light-color;

    @include hover-focus {
      color: $side-bar-light-hover-color;
    }

    &.disabled {
      color: $side-bar-light-disabled-color;
    }


  }

  .show > .nav-link,
  .active > .nav-link,
  .nav-link.show,
  .nav-link.active {
    color: $side-bar-light-active-color;
    background-color: $side-bar-light-active-bg;

    @include hover-focus {
      color: $side-bar-light-active-hover-color !important;
    }

    a.nav-label {
      color: $side-bar-light-active-color;
      background-color: $side-bar-light-active-bg;

      @include hover-focus {
        color: $side-bar-light-active-hover-color !important;
      }
    }
  }
}


.side-nav-dark {

}