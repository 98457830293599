$icomoon-font-family: "custom-icons" !default;
$icomoon-font-path: "../../font/fa-custom" !default;

$fa-custom-fertilizer: "\e907";
$fa-custom-growth-stage: "\e90c";
$fa-custom-nutrient: "\e900";
$fa-custom-equipment: "\e901";
$fa-custom-field-entry: "\e902";
$fa-custom-land2: "\e903";
$fa-custom-seed2: "\e904";
$fa-custom-client: "\e905";
$fa-custom-crop: "\e906";
$fa-custom-land: "\e908";
$fa-custom-pest: "\e909";
$fa-custom-pesticide: "\e90a";
$fa-custom-seed: "\e90b";

