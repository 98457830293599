@mixin accent-border($direction : 'none', $color : $accent-border-color, $width : $accent-border-width, $type : $accent-border-type) {
  
  @if $direction == 'none' {
    border: $width $type $color !important;
  } @else {
    border-#{$direction} :  $width $type $color !important;
  }
  
}

.accent {
  @include accent-border();
}

.accent-0 {
  border:none !important;
}

.accent-top {
  @include accent-border('top');
}

.accent-right {
  @include accent-border('right');
}

.accent-bottom {
  @include accent-border('bottom');
}

.accent-left {
  @include accent-border('left');
}

.accent-x {
  @include accent-border('left');
  @include accent-border('right');
}

.accent-y {
  @include accent-border('top');
  @include accent-border('bottom');
}

.accent-box {
  padding: $accent-box-padding;
  color: $accent-box-color;
  background-color: $accent-box-bg;
}