
.drag-drop.expandable  {
    .drag-drop-zone-col,
    .drag-drop-fl-col {
        @include transition($transition-base);
    }

    .drag-drop-zone-col {
        @include make-col(11);
    }

    .drag-drop-fl-col {
        @include make-col(1);
    }
    
    &.expand {
        .drag-drop-zone-col,
        .drag-drop-fl-col {
            @include make-col(6);
        }
    }
}

.drag-drop-zone {
    @include border-radius();
    border: $drag-drop-zone-border-width $drag-drop-zone-border-style $drag-drop-zone-border-color;
    
    .drag-drop-zone-inner {
        padding : map-get($spacers, 3) map-get($spacers, 5) map-get($spacers, 3) map-get($spacers, 5);
        color: $text-muted;
        text-align: center;
        
        > p {
            font-size: $drag-drop-zone-text-size;
            padding-top : map-get($spacers, 3);
        }
        
        > .drag-drop-zone-icon {
            font-size: $drag-drop-zone-icon-size
        }
    }

    &.is-dragging {
        opacity:0.2;
    }
}

.drag-drop-zone-fallback {
    text-align: center;
    > p {
        color: $text-muted;
        font-size: calc($drag-drop-zone-text-size / 2);
    }
}

.drag-drop-fl {
    
    &.scrollable-region {
        padding-right: map-get($spacers, 2);
        overflow:hidden;
        position: relative;
        flex: 1 1 auto;
        
        .scrollable-viewport {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding-right: map-get($spacers, 2);
        }
    }
    
    .drag-drop-fl-content {
        display:flex;
        flex-direction: column;
        &.drag-drop-fl-content-row {
            flex-direction: row !important;
        }
    }
    
    .drag-drop-fl-item {
        /*padding : map-get($spacers, 3) 0;*/
    }
}

.drag-drop-fl-item {
    display:flex;
    align-items: flex-start;
    
    padding : map-get($spacers, 3) map-get($spacers, 2);
    
    
    
    > .drag-drop-fl-item-img, 
    > .drag-drop-fl-item-icon {
        width:16.66667%;
        text-align: center;
        align-self: center;
    }
    
    > .drag-drop-fl-item-img {
        
    }
    
    > .drag-drop-fl-item-icon {
        color: $text-muted;
    }
    
    > .drag-drop-fl-item-body {
        flex: 1;
        
        .drag-drop-fl-item-title {
            // d-flex align-items-start justify-content-between pb-2
            display:flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-bottom:map-get($spacers, 2);
            
            .close {
                margin-right:map-get($spacers, 1);
            }
        }
    }
}

@mixin drag-drop-fl-variant($background) {
    background-color:$background;
    color: color-yiq($background);
    @include gradient-bg($background);
}



@each $color, $value in $theme-colors {
    .drag-drop-fl-#{$color} {
        @include drag-drop-fl-variant($value);
    }
}