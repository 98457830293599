@import "variables";
@import "partials/deps";

/* ==========================================================================
   Styles for login
   ========================================================================== */
#main {
  /*position: relative;
  width: 100vw;
  height: 100vh;*/
  font-family: $font-family-base;
}

.unauthorized .login-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  top: 0;
  left: 0;
  text-align: center;
  background-size: cover;

  .login-box-inner {
    width: 100%;
    max-width: 31.25em;

    @include box-shadow($box-shadow-sm);
  }
}

.before-container {

  main.unauthorized & {
    display: none;
  }

}