@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?eeuec6');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?eeuec6#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?eeuec6') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?eeuec6') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?eeuec6##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="fa-custom-"], [class*=" fa-custom-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-custom-fertilizer {
  &:before {
    content: $fa-custom-fertilizer; 
  }
}
.fa-custom-growth-stage {
  &:before {
    content: $fa-custom-growth-stage; 
  }
}
.fa-custom-nutrient {
  &:before {
    content: $fa-custom-nutrient; 
  }
}
.fa-custom-equipment {
  &:before {
    content: $fa-custom-equipment; 
  }
}
.fa-custom-field-entry {
  &:before {
    content: $fa-custom-field-entry; 
  }
}
.fa-custom-land2 {
  &:before {
    content: $fa-custom-land2; 
  }
}
.fa-custom-seed2 {
  &:before {
    content: $fa-custom-seed2; 
  }
}
.fa-custom-client {
  &:before {
    content: $fa-custom-client; 
  }
}
.fa-custom-crop {
  &:before {
    content: $fa-custom-crop; 
  }
}
.fa-custom-land {
  &:before {
    content: $fa-custom-land; 
  }
}
.fa-custom-pest {
  &:before {
    content: $fa-custom-pest; 
  }
}
.fa-custom-pesticide {
  &:before {
    content: $fa-custom-pesticide; 
  }
}
.fa-custom-seed {
  &:before {
    content: $fa-custom-seed; 
  }
}

