// Header bar component

.header-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  padding:$header-bar-padding-y $header-bar-padding-x;
  background: $header-bar-background;
  
  .header-icon {
    //font-size: $font-size-lg;
    display: inline-block;
    border-radius: 50%;
    border:$header-bar-icon-border-size solid $header-bar-icon-border-color;
    padding: $header-bar-icon-padding-y $header-bar-icon-padding-x;
    @include gradient-radial($header-bar-icon-bg-gradient-inner, $header-bar-icon-bg-gradient-outer);
    
    @if ( $header-bar-icon-border-shadow-enable ) {
      box-shadow: $header-bar-icon-border-shadow;
    }
    
  }

  @for $i from 1 through 6 {
    h#{$i} {
      margin-right: auto;
      font-size: map_get($header-bar-heading-font-size, $i);
    }
  }
  
  .header-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

