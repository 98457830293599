.password-peek {
  position: relative;

  .password-peek-btn {
    position: absolute;
    top: 50%;
    right: 0.5em;
    transform: translateY(-50%);
  }
}

.strength-meter {
  margin-top:1em;
}