// Variable file for setting default colors
// for more a full list see _variables.scss within bootstrap.
// for more information about how to set variables see https://getbootstrap.com/docs/4.1/getting-started/theming/

$image-path: "../../images";
$fa-font-path: "../../font/fa";

//$font-family-base   : "jaf-facitweb", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-base   : 'Lato', sans-serif;
$headings-font-family: 'industry', sans-serif;

$enable-gradients   : false;
$enable-shadows     : true;
$enable-rounded     : false;

$card-spacer-y:                     0.5rem !default;
$card-spacer-x:                     0.75rem !default;
$card-secondary-variant-enable-gradients : true;
$card-icon-font-size : 18px;

$side-bar-header-logo-padding : 1.35rem;
$side-bar-nav-font-weight : 600;

// color theme overrides
$body-color : #222222;
$accent     : #518003;
$blue       : #005fa2;
$body-bg    : #ffffff;
$primary    : #8bcb1b;
$secondary  : rgba(222,231,217,0.4);

$theme-colors: (
    "accent" : $accent,
    "gray-500" : #adb5bd,
    "gray-550" : #acacac,
    "gray-600" : #6c757d
);

// heading overrides
//$headings-color : #3b3030;
$h1-font-size : 1rem * 2;
$h2-font-size : 1rem * 1.75;
$h3-font-size : 1rem * 1.6;

$nav-tabs-link-active-bg  : $accent;
$nav-pills-link-active-bg : $accent;
$accent-box-bg            : #f4f6f2;
$link-color               : $blue;
$headings-font-weight     : 700;

$print-page-size          : a4 !default;


/*******************************************
  slim select overwrites
******************************************** */
$border-radius: 0 !default;
$spacer-ss: 0.5rem !default;

$spacing-l: $spacer-ss * 1.5;
$spacing-m: $spacer-ss ;
$spacing-s: $spacer-ss * 0.5;

// ideally we should pull this from bootstrap $input variables but those aren't loaded in yet.
$height: calc(2.25rem + 2px);

$input-btn-padding-y-xs:      .25rem !default;
$input-btn-padding-x-xs:      .5rem !default;
$input-btn-font-size-xs:      .65em !default;
$input-btn-line-height-xs:    1.5 !default;

$btn-padding-y-xs:            $input-btn-padding-y-xs !default;
$btn-padding-x-xs:            $input-btn-padding-x-xs !default;
$btn-font-size-xs:            $input-btn-font-size-xs !default;
$btn-line-height-xs:          $input-btn-line-height-xs !default;